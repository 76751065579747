import { type UseEmblaCarouselType } from 'embla-carousel-react';
import React from 'react';
import { CarouselProps } from './carousel-type.ts';

type CarouselContextProps = {
  carouselRef: UseEmblaCarouselType[0];
  api: UseEmblaCarouselType[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;

export const CarouselContext = React.createContext<CarouselContextProps | null>(
  null,
);
