import { FC, PropsWithChildren, ReactNode } from 'react';
import {
  carouselTriggerDescription,
  carouselTriggerHeading,
  carouselTriggerHeadingContainer,
  descriptionList,
} from './discover.css.ts';

interface CarouselTriggerContentProps extends PropsWithChildren {
  icon: ReactNode;
  title: string;
}

export const CarouselTriggerContent: FC<CarouselTriggerContentProps> = ({
  icon,
  title,
  children,
}) => {
  return (
    <>
      <div className={carouselTriggerHeadingContainer}>
        {icon}
        <h2 className={carouselTriggerHeading}>{title}</h2>
      </div>
      <div className={carouselTriggerDescription}>
        <ul className={descriptionList}>{children}</ul>
      </div>
    </>
  );
};
