import { waitlistCard, waitlistContent } from './waitlist-card.css.ts';
import { clsx } from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import { LayoutCard } from '@/components/layout/card.tsx';
import { welcomeCard } from '@/routes/waitlist/main-waitlist/main-waitlist.css.ts';

export const WaitlistCard: FC<ComponentPropsWithoutRef<'div'>> = ({
  children,
  className,
}) => {
  return (
    <LayoutCard className={clsx(waitlistCard, welcomeCard)}>
      <div style={{ width: '100%' }}>
        <div className={clsx(waitlistContent, className)}>{children}</div>
      </div>
    </LayoutCard>
  );
};
