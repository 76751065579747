// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgRefresh = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.75 12a9.25 9.25 0 0 1 17.19-4.75H17a.75.75 0 0 0 0 1.5h4.4a1.35 1.35 0 0 0 1.35-1.35V3a.75.75 0 0 0-1.5 0v3.52A10.75 10.75 0 0 0 12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75c5.39 0 9.852-3.966 10.63-9.138a.75.75 0 0 0-1.483-.224A9.25 9.25 0 0 1 2.75 12"
    />
  </svg>
);
export const RefreshIcon = forwardRef(SvgRefresh);
