// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgVerificationPurple = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    viewBox="0 0 19 19"
    ref={ref}
    {...props}
  >
    <g filter="url(#a)">
      <circle cx={9.5} cy={8.5} r={6.5} fill="#9C90C7" />
      <circle cx={9.5} cy={8.5} r={6} stroke="#7666AE" />
    </g>
    <path stroke="#fff" d="m5.5 8 3 3 4-5" />
    <defs>
      <filter
        id="a"
        width={19}
        height={19}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2136_7645"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2136_7645"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const VerificationPurpleIcon = forwardRef(SvgVerificationPurple);
