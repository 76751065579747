// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgLandingNews = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={53}
    fill="none"
    viewBox="0 0 51 53"
    ref={ref}
    {...props}
  >
    <g fill="currentColor">
      <path d="M45.244 13.652C48.42 13.652 51 11.08 51 7.917s-2.581-5.736-5.756-5.736c-3.174 0-5.755 2.572-5.755 5.736 0 1.087.308 2.108.84 2.979l-5.666 5.646a12.23 12.23 0 0 0-7.648-2.673c-2.89 0-5.549.999-7.649 2.673l-9.82-9.797a4.56 4.56 0 0 0 .532-2.148C10.078 2.064 8.006 0 5.458 0A4.61 4.61 0 0 0 .846 4.603c0 2.538 2.072 4.602 4.62 4.602.778 0 1.511-.19 2.15-.53l9.825 9.791a12.13 12.13 0 0 0-2.681 7.621c0 2.879 1.002 5.529 2.681 7.621l-6.086 6.064a7.23 7.23 0 0 0-4.081-1.255C3.264 38.517 0 41.764 0 45.758c0 3.995 3.264 7.248 7.273 7.248s7.273-3.253 7.273-7.248a7.17 7.17 0 0 0-1.26-4.067l6.086-6.064A12.23 12.23 0 0 0 27.02 38.3c2.89 0 5.549-.998 7.643-2.672l2.933 2.923a4.8 4.8 0 0 0-.599 2.327c0 2.672 2.184 4.848 4.866 4.848s4.865-2.176 4.865-4.848-2.184-4.848-4.865-4.848c-.846 0-1.64.217-2.335.597l-2.934-2.924a12.13 12.13 0 0 0 2.682-7.62c0-2.88-1.002-5.53-2.682-7.621l5.666-5.646c.874.53 1.893.837 2.99.837h-.006ZM5.46 6.482a1.884 1.884 0 0 1-1.887-1.88c0-1.037.846-1.88 1.887-1.88s1.887.843 1.887 1.88c0 1.038-.846 1.88-1.887 1.88m1.814 43.796c-2.503 0-4.54-2.031-4.54-4.525s2.037-4.525 4.54-4.525 4.54 2.031 4.54 4.525-2.037 4.525-4.54 4.525m34.59-11.527c1.175 0 2.133.954 2.133 2.126a2.134 2.134 0 0 1-4.266 0c0-1.177.957-2.126 2.133-2.126m-14.848-3.174c-5.252 0-9.53-4.262-9.53-9.495s4.278-9.496 9.53-9.496 9.529 4.263 9.529 9.496-4.272 9.495-9.53 9.495Zm18.23-30.679a3.02 3.02 0 0 1 3.023 3.013 3.02 3.02 0 0 1-3.024 3.013 3.02 3.02 0 0 1-3.023-3.013 3.02 3.02 0 0 1 3.023-3.013Z" />
      <path d="M27.014 21.34c-2.62 0-4.753 2.125-4.753 4.736s2.133 4.737 4.753 4.737 4.754-2.126 4.754-4.737c0-2.61-2.134-4.736-4.754-4.736m0 6.762a2.026 2.026 0 0 1-2.027-2.02c0-1.116.907-2.02 2.027-2.02s2.027.904 2.027 2.02-.907 2.02-2.027 2.02" />
    </g>
  </svg>
);
export const LandingNewsIcon = forwardRef(SvgLandingNews);
