// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgLandingOsint = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={66}
    fill="none"
    viewBox="0 0 59 66"
    ref={ref}
    {...props}
  >
    <g fill="currentColor">
      <path d="M5.267 5.099a1.03 1.03 0 0 0 1.027-1.034 1.03 1.03 0 0 0-1.027-1.034A1.03 1.03 0 0 0 4.24 4.065c0 .57.46 1.034 1.027 1.034m3.651.062a1.03 1.03 0 0 0 1.027-1.034c0-.57-.46-1.033-1.027-1.033A1.03 1.03 0 0 0 7.89 4.127c0 .571.46 1.034 1.027 1.034Zm3.657.064a1.03 1.03 0 0 0 1.027-1.034 1.03 1.03 0 0 0-1.027-1.034 1.03 1.03 0 0 0-1.027 1.034c0 .57.46 1.034 1.027 1.034" />
      <path d="M58.167 59.672 45.427 46.85a20.8 20.8 0 0 0 3.497-9.715h5.121a2.546 2.546 0 0 0 2.533-2.55V2.55A2.546 2.546 0 0 0 54.045 0H2.533A2.546 2.546 0 0 0 0 2.55v32.035a2.546 2.546 0 0 0 2.533 2.55h5.205c.999 10.546 9.854 18.822 20.596 18.822 4.31 0 8.32-1.334 11.638-3.618l12.74 12.823a2.83 2.83 0 0 0 2.013.838c.729 0 1.457-.28 2.013-.838l1.436-1.446a2.86 2.86 0 0 0 .833-2.025c0-.769-.298-1.48-.833-2.026zM7.64 35.2H2.533c-.34 0-.61-.28-.61-.615V8.032h43.316a.964.964 0 0 0 .958-.971.96.96 0 0 0-.958-.964H1.922V2.55c0-.342.278-.614.61-.614h51.513c.34 0 .61.279.61.614v3.548h-5.572a.97.97 0 0 0-.964.964c0 .53.43.97.964.97h5.573v26.554a.613.613 0 0 1-.61.615H49.02v-.063c0-11.482-9.278-20.82-20.687-20.82-4.31 0-8.313 1.334-11.63 3.611H9.631a.963.963 0 0 0-.958.964c0 .53.43.97.958.97h4.664a20.83 20.83 0 0 0-6.649 15.282v.063zm20.687 18.822c-10.347 0-18.771-8.471-18.771-18.885 0-10.413 8.417-18.892 18.771-18.892s18.765 8.472 18.765 18.892-8.418 18.885-18.765 18.885m28.48 8.325-1.436 1.446a.9.9 0 0 1-.653.272.9.9 0 0 1-.652-.272L41.519 51.166q1.502-1.248 2.741-2.759l12.547 12.627a.92.92 0 0 1 .27.657.92.92 0 0 1-.27.656" />
      <path d="M44.774 39.879c.43-1.509.66-3.101.66-4.742 0-9.492-7.675-17.216-17.1-17.216-3.892 0-7.71 1.355-10.735 3.813a.97.97 0 0 0-.146 1.362.956.956 0 0 0 1.354.147 14.95 14.95 0 0 1 9.528-3.38c8.369 0 15.183 6.85 15.183 15.28 0 1.23-.145 2.431-.423 3.577H13.567a15.2 15.2 0 0 1-.423-3.576c0-3.394 1.082-6.607 3.13-9.29a.965.965 0 0 0-.18-1.354.95.95 0 0 0-1.347.181 17.15 17.15 0 0 0-3.525 10.47c0 1.648.229 3.247.666 4.756a1 1 0 0 0 .035.126c2.095 7.123 8.653 12.334 16.405 12.334 7.751 0 14.302-5.21 16.398-12.327a.6.6 0 0 0 .041-.147zM28.328 50.411c-6.44 0-11.957-4.058-14.157-9.764h28.313c-2.2 5.706-7.717 9.764-14.156 9.764M5.656 14.185h2.95a.964.964 0 0 0 .957-.971.96.96 0 0 0-.958-.964h-2.95a.963.963 0 0 0-.957.964c0 .53.43.97.958.97Zm6.53 0h5.253a.97.97 0 0 0 .965-.971.96.96 0 0 0-.965-.964h-5.253a.963.963 0 0 0-.957.964c0 .53.43.97.957.97Zm26.009 0h12.686a.964.964 0 0 0 .957-.971.96.96 0 0 0-.957-.964H38.195a.97.97 0 0 0-.965.964c0 .53.43.97.965.97Zm-32.539 5.67H6.94a.966.966 0 0 0 .964-.97.96.96 0 0 0-.964-.964H5.656a.963.963 0 0 0-.958.964c0 .53.43.97.958.97m45.218-1.935h-5.253a.963.963 0 0 0-.958.965c0 .53.43.97.958.97h5.253a.964.964 0 0 0 .958-.97.96.96 0 0 0-.958-.964ZM8.085 23.983H5.649a.964.964 0 0 0-.958.971c0 .538.43.964.958.964h2.436a.963.963 0 0 0 .957-.964c0-.53-.43-.97-.957-.97Z" />
    </g>
  </svg>
);
export const LandingOsintIcon = forwardRef(SvgLandingOsint);
