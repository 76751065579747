// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgLandingTrading = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={59}
    fill="none"
    viewBox="0 0 56 59"
    ref={ref}
    {...props}
  >
    <g fill="currentColor">
      <path d="M11.058 40.704H5.512c-.569 0-1.028.458-1.028 1.025v12.454c0 .567.46 1.025 1.028 1.025h1.746v2.76c0 .567.458 1.025 1.027 1.025.568 0 1.027-.458 1.027-1.025v-2.76h1.746c.569 0 1.027-.458 1.027-1.025V41.73c0-.567-.459-1.025-1.027-1.025Zm-1.027 12.461H6.539V42.761h3.492zM22.16 27.416h-1.747v-3.922c0-.567-.458-1.024-1.027-1.024-.568 0-1.027.457-1.027 1.024v3.922h-1.746c-.568 0-1.027.458-1.027 1.025v12.454c0 .567.459 1.025 1.027 1.025h1.746v6.04c0 .566.459 1.024 1.027 1.024.569 0 1.027-.458 1.027-1.025V41.92h1.747c.568 0 1.027-.458 1.027-1.025V28.441c0-.567-.459-1.025-1.027-1.025M21.133 39.87H17.64V29.466h3.492zm23.472-25.14h-1.746v-1.606c0-.567-.459-1.024-1.027-1.024-.569 0-1.028.457-1.028 1.024v1.606h-1.746c-.568 0-1.027.458-1.027 1.025V37.07c0 .567.459 1.025 1.027 1.025h1.746v4.324c0 .568.46 1.025 1.028 1.025s1.027-.458 1.027-1.025v-4.324h1.746c.568 0 1.027-.458 1.027-1.025V15.755c0-.567-.459-1.025-1.027-1.025m-1.027 21.315h-3.5V16.78h3.5zm-10.284-8.629H31.43v-3.922c0-.567-.459-1.024-1.027-1.024-.569 0-1.027.457-1.027 1.024v3.922H27.74c-.568 0-1.027.458-1.027 1.025v8.765c0 .567.459 1.025 1.027 1.025h1.746v7.932c0 .567.459 1.024 1.027 1.024.569 0 1.027-.457 1.027-1.024V38.23h1.747c.568 0 1.027-.458 1.027-1.025V28.44c0-.567-.459-1.025-1.027-1.025h.007Zm-1.028 8.765h-3.492v-6.715h3.492zM54.972 3.976h-1.746V1.332c0-.567-.459-1.024-1.027-1.024-.569 0-1.027.457-1.027 1.024v2.644h-1.746c-.569 0-1.028.458-1.028 1.025V15.98c0 .567.46 1.025 1.028 1.025h1.746v4.324c0 .567.459 1.025 1.027 1.025s1.027-.458 1.027-1.025v-4.324h1.746c.569 0 1.027-.458 1.027-1.025V5c0-.567-.459-1.025-1.027-1.025Zm-1.027 10.98h-3.5v-8.93h3.5zm-32.909-4.462C21.036 4.707 16.318 0 10.518 0S0 4.707 0 10.494c0 5.786 4.718 10.494 10.518 10.494s10.518-4.708 10.518-10.494m-18.982 0c0-4.66 3.794-8.444 8.464-8.444s8.464 3.791 8.464 8.444c0 4.652-3.8 8.444-8.464 8.444-4.663 0-8.464-3.785-8.464-8.444" />
      <path d="M11.532 15.912v-.854q.059-.009.13-.027c1.143-.301 1.938-1.203 2.068-2.357.116-1.08-.377-2.07-1.267-2.535-1.089-.56-1.685-.82-1.712-.834a.5.5 0 0 0-.089-.034c-.144-.048-.466-.218-.555-.423q-.08-.194.076-.56c.137-.308.308-.404.438-.451.527-.192 1.363.102 1.61.218a1.026 1.026 0 1 0 .903-1.844 5.5 5.5 0 0 0-1.61-.465v-.67c0-.567-.458-1.024-1.026-1.024S9.47 4.509 9.47 5.077v1.045c-.506.294-.903.744-1.164 1.325-.445.998-.287 1.77-.075 2.241.452.99 1.445 1.407 1.732 1.51.138.061.672.3 1.548.751.117.062.192.274.164.486-.02.177-.116.484-.547.6-1.178.308-2.322-.245-2.356-.259a1.03 1.03 0 0 0-1.376.444c-.26.506-.062 1.12.445 1.38.04.02.691.349 1.63.499v.8c0 .566.458 1.024 1.027 1.024.568 0 1.027-.458 1.027-1.025z" />
    </g>
  </svg>
);
export const LandingTradingIcon = forwardRef(SvgLandingTrading);
