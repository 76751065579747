import {
  sectionContainer,
  sectionContent,
  sectionHeading,
  sectionSubHeading,
} from '../page.css.ts';
import { clsx } from 'clsx';
import {
  cardDescription,
  cardTitle,
  howToUseCard,
  howToUseContent,
  iconBox,
} from './how-to-use.css.ts';
import { LandingNewsIcon } from '@synoptic/ui-kit/icons/react/landing-news.tsx';
import { LandingOsintIcon } from '@synoptic/ui-kit/icons/react/landing-osint.tsx';
import { LandingForecastingIcon } from '@synoptic/ui-kit/icons/react/landing-forecasting.tsx';
import { LandingTradingIcon } from '@synoptic/ui-kit/icons/react/landing-trading.tsx';
import { LayoutCard } from '@/components/layout/card.tsx';

export const HowToUse = () => {
  return (
    <div className={clsx(sectionContainer)}>
      <div>
        <h1 className={sectionHeading}>
          How researchers benefit from Synoptic
        </h1>
        <p className={sectionSubHeading}>
          Amplify your insights, monetize your expertise, and build your
          reputation
        </p>
      </div>
      <div className={clsx(sectionContent, howToUseContent)}>
        <LayoutCard className={howToUseCard}>
          <div className={iconBox}>
            <LandingNewsIcon />
          </div>
          <div>
            <p className={cardTitle}>News</p>
            <p className={cardDescription}>
              Break stories first and establish yourself as a go-to source. Gain
              recognition for timely, accurate reporting.
            </p>
          </div>
        </LayoutCard>
        <LayoutCard className={howToUseCard}>
          <div className={iconBox}>
            <LandingOsintIcon />
          </div>
          <div>
            <p className={cardTitle}>OSINT</p>
            <p className={cardDescription}>
              Showcase your investigative skills, collaborate with peers, and
              earn credibility for verified discoveries.
            </p>
          </div>
        </LayoutCard>
        <LayoutCard className={howToUseCard}>
          <div className={iconBox}>
            <LandingForecastingIcon />
          </div>
          <div>
            <p className={cardTitle}>Forecasting</p>
            <p className={cardDescription}>
              Refine prediction skills across diverse fields. Leverage platform
              data for stronger forecasts.
            </p>
          </div>
        </LayoutCard>
        <LayoutCard className={howToUseCard}>
          <div className={iconBox}>
            <LandingTradingIcon />
          </div>
          <div>
            <p className={cardTitle}>Trading</p>
            <p className={cardDescription}>
              Share market insights, prove your analytical edge, and build a
              following based on your track record.
            </p>
          </div>
        </LayoutCard>
      </div>
    </div>
  );
};
