import { WaitlistCard } from '../waitlist-card/waitlist-card.tsx';
import { Brand } from './brand.tsx';
import { Header } from './header.tsx';
import { welcomeContainer } from './main-waitlist.css.ts';
import { cardBody, cardHeading } from '../waitlist-card/waitlist-card.css.ts';
import { WaitlistForm } from '../waitlist-card/form.tsx';

export const MainWaitlist = () => {
  return (
    <div className={welcomeContainer}>
      <Header />
      <Brand />
      <WaitlistCard>
        <h1 className={cardHeading}>Join the waitlist</h1>
        <p className={cardBody}>
          Stay tuned, we&apos;ll let you know as soon as Synoptic is available
        </p>
        <WaitlistForm />
      </WaitlistCard>
    </div>
  );
};
