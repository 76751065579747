import React, { ComponentPropsWithRef } from 'react';
import { clsx } from 'clsx';
import { carouselTrigger } from './carousel.css.ts';
import { useCarouselTrigger } from './use-carousel-trigger.ts';
import { useCarousel } from './use-carousel.ts';

interface CarouselTriggerProps extends ComponentPropsWithRef<'button'> {
  index: number;
}

export const CarouselTrigger: React.FC<CarouselTriggerProps> = ({
  children,
  className,
  index,
  ...props
}) => {
  const { api } = useCarousel();

  const { selectedIndex, handleTrigger, handleAutoplayTrigger } =
    useCarouselTrigger(api);

  return (
    <button
      type="button"
      className={clsx(carouselTrigger, className)}
      data-active={index === selectedIndex ? '' : undefined}
      onClick={() => handleAutoplayTrigger(() => handleTrigger(index))}
      {...props}
    >
      {children}
    </button>
  );
};
